/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-12 12:01:43
 * @Module: 电子文书
 */
<template>
  <div>

    <el-card shadow="never" :body-style="{position:'relative'}">
      <div slot="header" style="position: relative;">
        <hdq-section title="电子文书" more-text="" @more="more" />
        <el-button type="primary" style="position: absolute;top:0px;right: 24px;" @click="$store.commit('showToastDialog',true);">发起签署</el-button>
      </div>
      <filtrate @submit="getContract" :searchForm.sync="listParams" />
      <el-table :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <el-table-column label="合同名称" show-overflow-tooltip width="120">
          <template slot-scope="scope">{{ scope.row.subject }}</template>
        </el-table-column>
        <el-table-column label="文件信息" show-overflow-tooltip width="120">
          <template slot-scope="scope">
            <span class="pointer primary" @click="()=>downFile(item.fileId)" v-for="item in scope.row.files" :key="item.fileId">
              {{item.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="签署方信息" show-overflow-tooltip min-width="400">
          <template slot-scope="scope">
            <p v-for="(item,index) in  scope.row.signers" :key="index" class="signers">
              <!-- 个人 -->
              <el-popover popper-class="popper" placement="top" width="250" trigger="click" v-if="item.signerType=='PERSON'">
                <p style="display: flex;align-items: center;">
                  <el-avatar icon="el-icon-user-solid" style="background:#409EFF" />
                  <span style="margin-left:10px;">{{item.operatorName}}</span>
                </p>
                <div style="margin:12px 0px;height:1px;background:#eee;"></div>
                <p style="line-height:30px;">手机&nbsp;{{item.operatorPhone}}</p>
                <p style="line-height:30px;">身份证&nbsp;{{item.operatorIdNumber}}</p>
                <span slot="reference">
                  <span style="cursor: pointer;"><i class="el-icon-user" />{{item.operatorName}}</span>
                  <span v-if="scope.row.state !=='CANCELED'">
                    <span v-if="item.state =='IN_PROGRESS'" @click.stop="()=>showQRCode(item)">
                      <span>
                        <el-divider direction="vertical"></el-divider>
                        <el-link type="primary">签署</el-link>
                      </span>
                    </span>
                    <span v-else-if="item.state =='FINISHED'"><i class="el-icon-success" style="color:#67C23A;margin-left:6px;"></i></span>
                    <span v-else>
                      <el-divider direction="vertical"></el-divider>
                      {{ item.state|stateStyle}}
                    </span>
                  </span>
                </span>
              </el-popover>
              <!-- 公司 -->
              <el-popover popper-class="popper" placement="top" width="250" trigger="click" v-if="item.signerType=='ORG'">
                <p style="display: flex;align-items: center;">
                  <el-avatar icon="el-icon-user-solid" style="background:#409EFF" />
                  <span style="margin-left:10px;">{{item.operatorName}}</span>
                </p>
                <div style="margin:12px 0px;height:1px;background:#eee;"></div>
                <p style="line-height:30px;">手机&nbsp;{{item.operatorPhone}}</p>
                <p style="line-height:30px;">身份证&nbsp;{{item.operatorIdNumber}}</p>
                <p style="line-height:30px;">公司名称<br />{{item.name}}</p>
                <p style="line-height:30px;">统一社会信用代码<br />{{item.idNumber}}</p>
                <span slot="reference">

                  <span style="cursor: pointer;"><i class="el-icon-user" />

                    <span class="name">{{item.name}}</span>
                    -{{item.operatorName}}</span>
                  <span v-if="scope.row.state !=='CANCELED'">
                    <span @click.stop="()=>showQRCode(item)" v-if="item.state==='IN_PROGRESS'">
                      <span>
                        <el-divider direction="vertical"></el-divider>
                        <el-link type="primary">签署</el-link>
                      </span>
                    </span>
                    <span v-else-if="item.state=='FINISHED'">
                      <i class="el-icon-success" style="color:#67C23A;margin-left:6px;"></i>
                    </span>
                    <span v-else>
                      <el-divider direction="vertical"></el-divider>
                      {{ item.state|stateStyle}}
                    </span>
                  </span>
                </span>
              </el-popover>
            </p>
          </template>
        </el-table-column>

        <el-table-column label="归档" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.docUuid">
              <i class="el-icon-ali-wenjianjia" style="color:#0062FF;font-size:14px;margin-right:4px;" @click="()=>operate(scope)"></i>
              <span>{{ scope.row.dirName||'未归档' }}</span>
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="时间" show-overflow-tooltip width="120">
          <template slot-scope="scope">
            <div>
              <div>
                <span v-if="scope.row.initiatedAt" class="flex"> <img style="margin-right:4px;" src="@/static/image/electronicDocuments_shi.png" alt="" srcset="">{{ scope.row.initiatedAt|timeFormat('yyyy-mm-dd') }} </span>
              </div>
              <div>
                <span v-if="scope.row.finishedAt" class="flex"> <img style="margin-right:4px;" src="@/static/image/electronicDocuments_zhi.png" alt="" srcset="">{{ scope.row.finishedAt|timeFormat('yyyy-mm-dd') }} </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <p>
              <span v-if="scope.row.state=='IN_PROGRESS'">
                <i class="el-icon-ali-dian1" style="color:#409EFF;"></i>
                <span>
                  {{ scope.row.state|stateStyle }}
                </span>
                <el-divider direction="vertical"></el-divider>
                <el-link type="danger" @click="()=>deleteContract(scope.row)">撤销合同</el-link>
              </span>
              <span v-else-if="scope.row.state=='FINISHED'">
                <i class="el-icon-ali-dian1" style="color:#67C23A;"></i>
                <span>
                  {{ scope.row.state|stateStyle }}
                </span>
              </span>
              <span v-else>
                <i class="el-icon-ali-dian1" style="color:#F56C6C;"></i>
                <span>
                  {{ scope.row.state|stateStyle }}
                </span>
              </span>
            </p>
          </template>
        </el-table-column>
      </el-table>

      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="getContract" />
    </el-card>
    <qrDialog ref="qrDialog">
      <template slot-scope="scope">
        <span class="text">
          <span>请签署人<i style="color:#0062ff;">{{operatorName}}</i>用</span>
          <span>支付宝APP</span>
          <span>扫码并按照提示完成签署</span>
          <el-link type="primary" v-clipboard:copy="scope.url" v-clipboard:success="()=>$message({message:'地址复制成功',type: 'success'})">(点击复制地址)</el-link>
        </span>
      </template>
    </qrDialog>
    <fileSelect @callback="fileSelectCallback" ref="fileSelect" />
  </div>
</template>
<script>
import hdqPagination from "@/components/hdqPagination.vue"
import qrDialog from "@/components/qrDialog.vue"
import fileSelect from "@/components/fileSelect.vue"
import filtrate from "@/views/ElectronicDocuments/components/filtrate.vue";
import { mapState } from "vuex";

export default {
  components: {
    hdqPagination,
    qrDialog,
    fileSelect,
    filtrate
  },
  data () {
    return {
      list: [],
      operateId: [],
      operatorName: '',
      listParams: {
        keyword: ''
      },
    };
  },
  computed: {
    ...mapState({
      companyIdNumber: state => state.user.user.companyIdNumber
    })
  },
  mounted () {
    this.getContract()
  },
  filters: {
    stateStyle (type) {
      if (type == 'IN_PROGRESS') {
        return '签署中'
      } else if (type == 'FINISHED') {
        return '签署完成'
      } else if (type == 'CANCELED') {
        return '已撤消'
      } else if (type == 'TERMINATED') {
        return '终止'
      } else if (type == 'EXPIRED') {
        return '过期'
      } else if (type == 'REFUSED') {
        return '拒签'
      } else {
        return type
      }

    }
  },
  methods: {
    async getContract () {
      const { current, size } = this.$refs.pagination
      const listParams = this.listParams;
      const { status, data } = await this.$api.getContract({ current, size, ...listParams })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
    more () {
      this.$router.push({
        name: 'electronicDocumentsAdd'
      })
    },
    downFile (fileId) {
      this.$store.dispatch('documents/getFile', {
        fileId, callback: (res) => {
          this.$utils.open(res.downloadUrl)
        }
      })
    },
    showQRCode ({ uuid, operatorName, operatorIdNumber }) {

      this.$prompt(`请输入签署人${operatorName}身份证后四位`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: `请输入签署人${operatorName}身份证后四位`,
        inputPattern: /^[0-9xX]{4}$/,
        inputErrorMessage: '身份证后四位格式不正确',
        type: 'warning'
      }).then(({ value }) => {
        if (operatorIdNumber.substring(operatorIdNumber.length - 4) == value) {
          this.operatorName = operatorName;
          this.getSignerUrl({ uuid });
        } else {
          this.$message({
            type: 'error',
            message: `您输入的${operatorName}身份证后四位错误`
          });
        }
      }).catch(() => ({}));
    },
    async getSignerUrl (parm) {
      const { status, data, info } = await this.$api.getSignerUrl(parm)
      if (status === 200) {
        this.$refs.qrDialog.show(data.signUrl)
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    },

    operate ({ row: { docUuid } }) {
      if (docUuid) {
        this.operateId = [docUuid];
        this.$refs.fileSelect.show();
      } else {
        this.$message({
          type: 'warning',
          message: '签署完成才可归档'
        })
      }
    },
    //  归档选择回调
    fileSelectCallback ({ data }) {
      this.$confirm(`选择归档目录为${data.label}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.putArchiveDocArvhive({
          parentUuid: data.uuid,
          uuidList: this.operateId
        }).then(({ status, info }) => {
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            });
            this.$refs.fileSelect.hidden();
            this.getContract()
          } else {
            this.$message({
              type: 'error',
              message: info
            });
          }
        })

      })
    },
    // 撤销合同
    async deleteContract ({ uuid, subject }) {
      const { current, size } = this.$refs.pagination;
      try {
        await this.$confirm(`撤销合同《${subject}》,撤销后不可恢复,请慎重操作。是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteContract({ uuid })
        if (status == 200) {
          this.$message({
            type: 'success',
            message: info
          })
          console.log(current, size)
          setTimeout(() => this.getContract(current, size), 1000)
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
        if (error == 'cancel') {
          this.$message({
            message: `取消撤销《${subject}》`
          })
        }
      }
    }
  },
};
</script>
<style lang='scss' scoped>
@import "~@/assets/style/var.scss";
.el-icon-user .el-icon-ali-wenjianjia {
  color: $--color-primary;
  margin-right: 5px;
}
.flex {
  display: flex;
  align-items: center;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
	display: inline-block;
	max-width: 250px;
	line-height: 1;
}
</style>